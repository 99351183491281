(function() {
  'use strict';

  angular.module('iv.authentication', [
    'ui.router',
    'angular-jwt'
  ]);

})();

(function() {
  'use strict';

  function configBase($httpProvider) {
    $httpProvider.interceptors.push('ivAuthenticateInterceptor');
  }

  configBase.$inject = ['$httpProvider'];

  angular.module('iv.authentication')
    .config(configBase);

})();
(function() {
  'use strict';

  function configRun($state, $transitions, ivAuthenticate, $window) {
    $transitions.onEnter({}, function(trans, state) {
      if(
        ivAuthenticate.isAuthenticated() === false && ivAuthenticate.isInsecureState(state.name) === false &&
        (
          ivAuthenticate.isShortTermState(state.name) === false ||
          (ivAuthenticate.isShortTermState(state.name) && !sessionStorage.getItem(ivAuthenticate.getLocalShortTokenStorageKey()))
        )
      ) {
        return $window.location.href = $state.href(ivAuthenticate.getRedirectState(), {}, {absolute: true});
      }
    });
  }

  configRun.$inject = ['$state', '$transitions','ivAuthenticate', '$window'];
  angular.module('iv.authentication')
    .run(configRun);

})();

(function() {
  'use strict';

  function ivAuthenticate() {
    var _this = this;

    var _authorizationHeader        = 'Authorization';
    var _loginUrl                   = '/authenticate';
    var _shortTokenLoginUrl         = '/authenticate';
    var _refreshTokenUrl            = '/refresh-token';
    var _loginHttpMethod            = 'POST';
    var _shortTokenLoginHttpMethod  = 'POST';
    var _accessToken;
    var _shortTermAccessToken;
    var _accessTokenType;
    var _shortTermAccessTokenType;
    var _authorizationScopes        = [];
    var _customAuthorizationScopes  = [];
    var _localStorageKey            = 'access_token';
    var _localShortTokenStorageKey  = 'short_token';
    var _refreshTokenKey            = 'refresh_token';
    var _redirectState              = 'login';
    var _insecureStates             = [];
    var _shortTermStates            = [];
    var _clientSideApp              = true;

    _this.$get  = service;

    /**
    * Service definition
    *
    * @returns {Object}
    */
    service.$inject  = ['$http', '$q', '$state', '$rootScope', 'jwtHelper'];

    function service($http, $q, $state, $rootScope, jwtHelper) {

      var module  = {
        login                         : login,
        shortTermLogin                : shortTermLogin,
        logout                        : logout,
        isAuthenticated               : isAuthenticated,
        setCustomAuthorizationScopes  : setCustomAuthorizationScopes,
        getAuthorizationScopes        : getAuthorizationScopes,
        getRedirectState              : getRedirectState,
        getRefreshTokenKey            : getRefreshTokenKey,
        getRefreshTokenUrl            : getRefreshTokenUrl,
        addInsecureState              : addInsecureState,
        addShortTermState             : addShortTermState,
        isInsecureState               : isInsecureState,
        isShortTermState              : isShortTermState,
        getAccessToken                : getAccessToken,
        getLocalShortTokenStorageKey  : getLocalShortTokenStorageKey,
        getJWTClaims                  : getJWTClaims,
        isTokenExpired                : isTokenExpired,
        isShortTermTokenExpired       : isShortTermTokenExpired,
        getAuthorizationHeader        : getAuthorizationHeader,
        isClientSideApp               : isClientSideApp,
        doRefreshLogin                : doRefreshLogin,
        setLoginUrl                   : _this.setLoginUrl,
        setShortTokenLoginUrl         : _this.setShortTokenLoginUrl,
        setRefreshTokenUrl            : _this.setRefreshTokenUrl,
        handleLoginSuccess            : handleLoginSuccess,
        handleShortTokenLoginSuccess  : handleShortTokenLoginSuccess,
        isStorageAvailable            : isStorageAvailable
      };

      function handleLoginSuccess(response) {
        if((angular.isObject(response) && angular.isObject(response.data)) && !angular.isDefined(response.data.error) && (angular.isDefined(response.data.access_token) && angular.isDefined(response.data.type) && angular.isDefined(response.data.scopes))) {
          _accessToken          = response.data.access_token;
          _accessTokenType      = response.data.type;
          _authorizationScopes  = response.data.scopes;

          $http.defaults.headers.common['Authorization'] = _accessTokenType + ' ' + _accessToken;

          if(isStorageAvailable()) {
            localStorage.setItem(_localStorageKey, JSON.stringify(response.data));
          }

          if(angular.isDefined(response.data.refresh_token)) {
            if(isStorageAvailable()) {
              var storageReference = (_clientSideApp) ? sessionStorage : localStorage;
              storageReference.setItem(_refreshTokenKey, response.data.refresh_token);
            }
            delete response.data.refresh_token;
          }

          if(_this.loginDeferred) {
            _this.loginDeferred.resolve(response);
          }
        } else {
          if(_this.loginDeferred) {
            _this.loginDeferred.reject(response.data);
          }
        }
      }

      function handleLoginError(response) {
        if(angular.isDefined(response.data)) {
          _this.loginDeferred.reject(response.data);
        } else {
          _this.loginDeferred.reject(response);
        }
      }

      function isStorageAvailable() {
        try {
          var test = 'test';
          localStorage.setItem(test, test);
          localStorage.removeItem(test);
          return true;
        } catch(e) {
          return false;
        }
      }

      /**
      * Do login attempt
      *
      */
      function login(params) {
        if(angular.isDefined(_this.loginDeferred) && _this.loginDeferred !== null) {
          return _this.loginDeferred.promise;
        }

        _this.loginDeferred  = $q.defer();

        params  = (typeof params === 'object') ? params : {};

        // default do credentials login
        if(!angular.isDefined(params.grant_type)) {
          params.grant_type = 'credentials';
        }

        $http({
          method  : _loginHttpMethod,
          url     : _loginUrl,
          data    : params
        }).then(handleLoginSuccess, handleLoginError);

        _this.loginDeferred.promise.finally(function() {
          _this.loginDeferred = null;
        });

        return _this.loginDeferred.promise;
      }

      /**
       * Do short term login attempt
       *
       */
      function shortTermLogin(params) {
        if(angular.isDefined(_this.shortLoginDeferred) && _this.shortLoginDeferred !== null) {
          return _this.shortLoginDeferred.promise;
        }

        _this.shortLoginDeferred  = $q.defer();

        params  = (typeof params === 'object') ? params : {};

        // default do credentials login
        if(!angular.isDefined(params.grant_type)) {
          params.grant_type = 'short_term';
        }

        $http({
          method  : _shortTokenLoginHttpMethod,
          url     : _shortTokenLoginUrl,
          data    : params
        }).then(handleShortTokenLoginSuccess, handleShortTokenLoginError);

        _this.shortLoginDeferred.promise.finally(function() {
          _this.shortLoginDeferred = null;
        });

        return _this.shortLoginDeferred.promise;
      }

      function handleShortTokenLoginSuccess(response) {
        if((angular.isObject(response) && angular.isObject(response.data)) &&
          !angular.isDefined(response.data.error) &&
          (angular.isDefined(response.data.access_token) && angular.isDefined(response.data.type) && angular.isDefined(response.data.scopes))
        ) {
          _shortTermAccessToken     = response.data.access_token;
          _shortTermAccessTokenType = response.data.type;
          _authorizationScopes      = response.data.scopes;

          $http.defaults.headers.common['Authorization'] = _shortTermAccessTokenType + ' ' + _shortTermAccessToken;

          if(isStorageAvailable()) {
            sessionStorage.setItem(_localShortTokenStorageKey, JSON.stringify(response.data));
          }

          if(_this.shortLoginDeferred) {
            _this.shortLoginDeferred.resolve(response);
          }
        } else {
          if(_this.shortLoginDeferred) {
            _this.shortLoginDeferred.reject(response.data);
          }
        }
      }

      function handleShortTokenLoginError(response) {
        if(angular.isDefined(response.data)) {
          _this.shortLoginDeferred.reject(response.data);
        } else {
          _this.shortLoginDeferred.reject(response);
        }
      }

      function getAuthorizationHeader() {
        return {
          'Authorization' : _accessTokenType + ' ' +  _accessToken
        };
      }

      function getAccessToken() {
        return _accessToken;
      }

      function getLocalShortTokenStorageKey() {
        return _localShortTokenStorageKey;
      }

      function getJWTClaims() {
        if(_accessToken) {
          return jwtHelper.decodeToken(_accessToken);
        }
      }

      function isTokenExpired() {
        if(_accessToken) {
          return jwtHelper.isTokenExpired(_accessToken);
        }
      }

      function isShortTermTokenExpired() {
        if(_shortTermAccessToken) {
          return jwtHelper.isTokenExpired(_shortTermAccessToken);
        }
      }

      function isClientSideApp() {
        return _clientSideApp;
      }

      function doRefreshLogin() {
        if(isStorageAvailable()) {
          var storageReference  = (_clientSideApp) ? sessionStorage : localStorage;

          if(storageReference.getItem(_refreshTokenKey) === null) {
            $q.reject('no refresh token available');
          }

          return login({
            grant_type    : 'refresh_token',
            refresh_token : storageReference.getItem(_refreshTokenKey)
          });
        }
      }

      function logout() {
        var deferred  = $q.defer();

        try {
          delete $http.defaults.headers.common['Authorization'];
        } catch(e) {
          $http.defaults.headers.common['Authorization']  = null;
        }

        if(isStorageAvailable()) {
          localStorage.removeItem(_localStorageKey);
          sessionStorage.removeItem(_localShortTokenStorageKey);
          if(_clientSideApp) {
            sessionStorage.removeItem(_refreshTokenKey);
          } else {
            localStorage.removeItem(_refreshTokenKey);
          }
        }

        _accessToken  = undefined;
        _shortTermAccessToken  = undefined;
        _authorizationScopes        = [];
        _customAuthorizationScopes  = [];

        deferred.resolve();

        return deferred.promise;
      }

      /**
      * Get the key for the refresh token in the session/local storage
      *
      * @returns {String}
      */
      function getRefreshTokenKey() {
        return _refreshTokenKey;
      }

      /**
      * Is there an active authenticated user
      *
      * @returns {Boolean}
      */
      function isAuthenticated() {
        if(isStorageAvailable() === false) {
          return false;
        }

        if(typeof _accessToken !== 'undefined' && jwtHelper.isTokenExpired(_accessToken) === false) {
          return true;
        } else if(localStorage.getItem(_localStorageKey) !== null) {
          var oAccessToken = JSON.parse(localStorage.getItem(_localStorageKey));
          var storageReference  = (_clientSideApp) ? sessionStorage : localStorage;

          if(jwtHelper.isTokenExpired(oAccessToken.access_token) && storageReference.getItem(_refreshTokenKey) === null) {
            localStorage.removeItem(_localStorageKey);
            return false;
          }

          _accessToken          = oAccessToken.access_token;
          _accessTokenType      = oAccessToken.type;
          _authorizationScopes  = oAccessToken.scopes;

          $http.defaults.headers.common['Authorization'] = _accessTokenType + ' ' + _accessToken;

          if(storageReference.getItem(_refreshTokenKey) === null) {
            $http({
              method  : 'GET',
              url     : _refreshTokenUrl
            }).then(function(response) {
              if(angular.isDefined(response.data) && response.data !== false) {
                storageReference.setItem(_refreshTokenKey, response.data);
              }
            }, function(rejection) {
              if(rejection.status === 401) {
                if(isInsecureState($state.current.name) === false) {
                  $state.go(_redirectState);
                }
              }
            });
          }
          return true;
        } else if(sessionStorage.getItem(_localShortTokenStorageKey) !== null) {
          var oShortTermAccessToken = JSON.parse(sessionStorage.getItem(_localShortTokenStorageKey));

          if(jwtHelper.isTokenExpired(oShortTermAccessToken.access_token)) {
            sessionStorage.removeItem(_localShortTokenStorageKey);
            return false;
          }

          _shortTermAccessToken     = oShortTermAccessToken.access_token;
          _shortTermAccessTokenType = oShortTermAccessToken.type;
          _authorizationScopes      = oShortTermAccessToken.scopes;

          $http.defaults.headers.common['Authorization'] = _shortTermAccessTokenType + ' ' + _shortTermAccessToken;
        }
        return false;
      }

      function addInsecureState(state) {
        if(typeof state === 'string') {
          _insecureStates.push(state);
        }
        return _this;
      }

      function addShortTermState(state) {
        if(typeof state === 'string') {
          _shortTermStates.push(state);
        }
        return _this;
      }

      /**
      * Check if specific state is a insecure state
      *
      */
      function isInsecureState(state) {
        if(_insecureStates.indexOf(state) !== -1) {
          return true;
        }
        return false;
      }

      /**
       * Check if specific state is a short term state
       *
       */
      function isShortTermState(state) {
        if(_shortTermStates.indexOf(state) !== -1) {
          return true;
        }
        return false;
      }

      /**
       * Add authorization scopes
       *
       * @return {boolean}
       */
      function setCustomAuthorizationScopes(scopes) {
        if(typeof scopes === 'object') {
          for(var i in scopes) {
            if(typeof scopes[i] !== 'string') {
              return false;
            } else {
              if(_customAuthorizationScopes.indexOf(scopes[i]) === -1) {
                _customAuthorizationScopes.push(scopes[i])
                $rootScope.$broadcast('iv.authenticate.customscope');
              }
            }
          }
          return true;
        } else if(typeof scopes === 'string') {
          if(_customAuthorizationScopes.indexOf(scopes) === -1) {
            _customAuthorizationScopes.push(scopes)
            $rootScope.$broadcast('iv.authenticate.customscope');
          }
        }
        return false;
      }

      /**
      * Get authorization scopes for authenticated user
      *
      * @returns {Array}
      */
      function getAuthorizationScopes() {
        isAuthenticated(); // Make sure authorization scopes are filled when logged in
        return _authorizationScopes.concat(_customAuthorizationScopes);
      }

      /**
      * Get redirect state
      *
      */
      function getRedirectState() {
        return _redirectState;
      }

      function getRefreshTokenUrl() {
        return _refreshTokenUrl;
      }

      return module;
    }

    /**
    * Provider methods
    *
    */
    _this.setLoginUrl = function(url) {
      if(typeof url === 'string') {
        _loginUrl = url;
      }
      return _this;
    };

    /**
     * Provider methods
     *
     */
    _this.setShortTokenLoginUrl = function(url) {
      if(typeof url === 'string') {
        _shortTokenLoginUrl = url;
      }
      return _this;
    };

    _this.setRefreshTokenUrl = function(url) {
      if(typeof url === 'string') {
        _refreshTokenUrl = url;
      }
      return _this;
    };

    _this.setLoginHttpMethod  = function(method) {
      if(typeof method === 'string') {
        _loginHttpMethod  = method;
      }
      return _this;
    };

    _this.setShortTokenLoginHttpMethod  = function(method) {
      if(typeof method === 'string') {
        _shortTokenLoginHttpMethod  = method;
      }
      return _this;
    };

    _this.setRedirectState  = function(state) {
      if(typeof state === 'string') {
        _redirectState  = state;
      }
      return _this;
    };

    _this.addInsecureState  = function(state) {
      if(typeof state === 'string') {
        _insecureStates.push(state);
      }
      return _this;
    };

    _this.addShortTermState  = function(state) {
      if(typeof state === 'string') {
        _shortTermStates.push(state);
      }
      return _this;
    };

    _this.useAsClientSideApp = function(bIsClientSideApp) {
      if(typeof bIsClientSideApp === 'boolean') {
        _clientSideApp  = bIsClientSideApp;
      }
      return _this;
    };

  }

  angular.module('iv.authentication')
    .provider('ivAuthenticate', ivAuthenticate);

})();

(function() {
  'use strict';

  ivAuthenticateInterceptor.$inject  = ['$injector', '$q', '$rootScope'];

  function ivAuthenticateInterceptor($injector, $q, $rootScope) {
    return {
      responseError  : function(rejection) {
        if(rejection.status === 401) {
          var output;
          var ivAuthenticate  = $injector.get('ivAuthenticate');

          if(rejection.config.url === ivAuthenticate.getRefreshTokenUrl()) {
            $rootScope.$broadcast('iv.authenticate.aborted');
            return $q.reject(rejection);
          }

          if(ivAuthenticate.isStorageAvailable() === false) {
            return $q.reject(rejection);
          }

          var deferred  = $q.defer();

          var storageReference  = (ivAuthenticate.isClientSideApp()) ? sessionStorage : localStorage;

          if(storageReference.getItem(ivAuthenticate.getRefreshTokenKey()) !== null) {
            ivAuthenticate.login({
              refresh_token : storageReference.getItem(ivAuthenticate.getRefreshTokenKey()),
              grant_type    : 'refresh_token'
            }).then(function(response) {
              if(angular.isObject(response) && angular.isDefined(response.data)) {
                // Refreshing token failed
                if(angular.isObject(response.data) && angular.isDefined(response.data.error) && response.data.error === true) {
                  deferred.reject(response);
                // Refresh succeeded. Replay original call
                } else {

                  var $http = $injector.get('$http');
                  $http({
                    method  : rejection.config.method,
                    url     : rejection.config.url,
                    data    : rejection.config.data, // 20170321 - rob@ivengi.com: Updated from rejection.config.params to rejection.config.data due to empty replay posts
                    params  : rejection.config.params // add data and params object. Otherwise get params will no be sent
                  }).then(function(response) {
                    deferred.resolve(response);
                  }, function(rejection) {
                    deferred.reject(rejection);
                  });

                }
              }
            }, function() {
              deferred.reject(rejection);
            });
          } else {
            return $q.reject(rejection);
          }

          return deferred.promise;
        } else {
          return $q.reject(rejection);
        }
      }
    };
  }

  angular.module('iv.authentication')
    .factory('ivAuthenticateInterceptor', ivAuthenticateInterceptor);

})();

(function() {
  'use strict';

  ivAuthorizedFor.$inject = ['ivAuthenticate', '$rootScope'];

  function ivAuthorizedFor(ivAuthenticate, $rootScope) {
    var DDO = {
      restrict  : 'A',
      link      : linkFn
    };

    /**
    * Directive link function
    *
    * @param scope
    * @param iElem
    * @param iAttrs
    */
    function linkFn(scope, iElem, iAttrs) {

      $rootScope.$on('iv.authenticate.customscope', handleVisibility);

      function handleVisibility() {
        var authorizedFor = ivAuthenticate.getAuthorizationScopes();
        var aScopes       = iAttrs.ivAuthorizedFor.split(',');
        var authorized    = false;

        for(var i in aScopes) {
          if(authorizedFor.indexOf(aScopes[i].trim()) !== -1) {
            authorized  = true;
            break;
          }
        }

        if(authorized === false) {
          iElem.addClass('ng-hide');
        } else {
          iElem.removeClass('ng-hide');
        }
      }

      handleVisibility();
    }

    return DDO;

  }

  angular.module('iv.authentication')
    .directive('ivAuthorizedFor', ivAuthorizedFor);

})();

